/**
 * Created by andrey on 12.12.19.
 */

var DailyLevelIcon = function () {
    SideBarIcon.call(this, {
        animation: bundles.sidebar.jsons.daily_level_json
    });
};

DailyLevelIcon.prototype = Object.create(SideBarIcon.prototype);
DailyLevelIcon.prototype.constructor = DailyLevelIcon;

DailyLevelIcon.prototype.resetState = function () {
    this.available = cleverapps.dailyLevel.isActive();

    this.setAttention(true);
};

DailyLevelIcon.prototype.onPressed = function () {
    cleverapps.meta.display({
        focus: "DailyLevel",
        action: function (f) {
            cleverapps.dailyLevel.start(f);
        }
    });
};
